import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { CircleFlag } from "react-circle-flags";
import { FaCheck } from "react-icons/fa";
import styled from "styled-components";

import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { P } from "../styles/text-styles";
import calculateAge from "../utilities/calculate-age";
import { countryCodes } from "../utilities/country-codes";
import { sports } from "../utilities/sport-mapping";

interface AthleteProps {
  name: string;
  country: string;
  sport: string;
  image: any;
  birthday: string;
  slug: string;
  verified: boolean;
}

export default function AthletePanel(props: AthleteProps) {
  const { name, country, sport, image, birthday, slug, verified } = props;
  const age = birthday ? calculateAge(birthday) : null;

  return (
    <Panel>
      <Link to={`/athletes/${sport}/${slug}`}>
        {/* TODO: Zoom on hover animation */}
        <Image image={getImage(image)!} alt={name} />
      </Link>
      <Body>
        <Link to={`/athletes/${sport}/${slug}`}>
          <NameAndCheck>
            <NameWrapper>{name}</NameWrapper>
            {verified && <CheckCircle title={"Verified"} size={16} />}
          </NameAndCheck>
        </Link>
        <BottomText>
          <SportText>
            {sports[sport]}
            {birthday && `, ${age}`}
          </SportText>
          <CountryBlock>
            <CountryText>{country}</CountryText>
            <CircleFlag countryCode={countryCodes[country]} height="20" />
          </CountryBlock>
        </BottomText>
      </Body>
    </Panel>
  );
}

const Panel = styled.div`
  border-radius: 12px;
  background-color: ${colors.gray2};
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const Image = styled(GatsbyImage)`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
`;

// TODO: space between for athlete name wrap
const Body = styled.div`
  padding: 24px;

  ${breakpoints.md} {
    padding: 36px;
  }
`;

const NameAndCheck = styled.div`
  margin-bottom: 16px;
  color: white;
  display: inline-flex;
  align-items: center;
`;

const NameWrapper = styled.span`
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: white;
`;

const CheckCircle = styled(FaCheck)`
  background-color: ${colors.primary3};
  border-radius: 12px;
  margin-left: 8px;
  padding: 3.5px;
`;

const BottomText = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SportText = styled(P)`
  color: ${colors.gray4};
`;

const CountryBlock = styled.div`
  display: flex;
  align-items: center;
`;

const CountryText = styled(P)`
  margin-right: 6px;
  color: ${colors.gray4};
`;
