import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { SmSection } from "../styles/structures";
import { H6 } from "../styles/text-styles";
import AthletePanel from "./AthletePanel";
import AdBanner from "./banners/AdBanner";

function areFiltersEmpty(filters: any) {
  return (
    filters.sport === "" && filters.gender === "" && filters.country === ""
  );
}

export default function AthletesList() {
  const useAthleteQuery = () => {
    const { athletes, countries } = useStaticQuery(
      graphql`
        query AthletesExplore {
          athletes: allContentfulAthlete(
            sort: { order: ASC, fields: lastName }
          ) {
            nodes {
              id
              name
              sport
              country
              gender
              birthday
              slug
              verified
              image {
                gatsbyImageData
              }
            }
          }
          countries: allContentfulAthlete {
            distinct(field: country)
          }
        }
      `
    );

    return { athletes: athletes.nodes, countries: countries.distinct };
  };

  const { athletes, countries } = useAthleteQuery();

  const [filters, setFilters] = useState({
    sport: "",
    gender: "",
    country: "",
  });

  function handleChange(
    e: React.ChangeEvent<HTMLSelectElement>,
    filter: string
  ) {
    setFilters({
      ...filters,
      [filter as string]: e.target.value,
    });
  }

  return (
    <SmSection>
      <FilterContainer>
        <Filter>
          <FilterName>Sport</FilterName>
          <Select
            name="sport"
            id="sport"
            onChange={e => handleChange(e, "sport")}
            value={filters.sport}
          >
            <option value="">All</option>
            <option value="skiers">Skiers</option>
            <option value="snowboarders">Snowboarders</option>
          </Select>
        </Filter>
        <Filter>
          <FilterName>Gender</FilterName>
          <Select
            name="gender"
            id="gender"
            onChange={e => handleChange(e, "gender")}
            value={filters.gender}
          >
            <option value="">All</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Select>
        </Filter>
        <Filter>
          <FilterName>Country</FilterName>
          <Select
            name="country"
            id="country"
            onChange={e => handleChange(e, "country")}
            value={filters.country}
          >
            <option value="">All</option>
            {countries.map((country: string) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </Select>
        </Filter>
        <ButtonWrapper>
          <ResetButton
            disabled={areFiltersEmpty(filters)}
            onClick={e => setFilters({ gender: "", sport: "", country: "" })}
          >
            Reset Filters
          </ResetButton>
        </ButtonWrapper>
      </FilterContainer>

      <AdBanner pageLocation="athletes" />

      <AthletesWrapper>
        {/* TODO: Sort by last name, either here or in the query */}
        {athletes
          .filter((athlete: any) => {
            for (const [key, value] of Object.entries(filters)) {
              if (value === "") continue;
              if (athlete[key] != value) return false;
            }
            return true;
          })
          .map((athlete: any) => (
            <AthletePanel
              key={athlete.id}
              name={athlete.name}
              sport={athlete.sport}
              country={athlete.country}
              image={athlete.image}
              birthday={athlete.birthday}
              slug={athlete.slug}
              verified={athlete.verified}
            />
          ))}
      </AthletesWrapper>
    </SmSection>
  );
}

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 9rem;
`;

const Select = styled.select`
  // TODO: Add background arrow png with appearance: none
  // for dropdown arrow in safari
  -webkit-appearance: listbox;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  background-color: ${colors.gray3};
  border: none;
  border-radius: 10px;
  height: 40px;
  padding: 0 10px 0 10px;
  margin-top: 8px;
  width: 100%;
  cursor: pointer;
`;

const FilterName = styled(H6)`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 9rem;
  align-self: flex-end;
`;

const ResetButton = styled.button`
  color: ${colors.gray4};
  font-size: 16px;
  font-weight: 600;

  background-color: ${colors.gray2};
  border: none;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  padding: 0 10px 0 10px;

  ${props =>
    !props.disabled &&
    `
    color: ${colors.white};
    background-color: ${colors.primary5};
    cursor: pointer;

    :hover {
      filter: brightness(1.1);
    }

    :active {
      filter: brightness(0.9);
    }
  `}
`;

const AthletesWrapper = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  ${breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }

  ${breakpoints.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
