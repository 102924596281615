import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";

import { colors } from "../styles/colors";
import { Section } from "../styles/structures";
import { H4 } from "../styles/text-styles";

export default function ExploreBodyText() {
  return (
    <Section>
      <Body>
        Our list of athletes is always growing. If you didn't find who you're
        looking for, check back later for more. Otherwise, head to our{" "}
        <Link to="/contact">
          <span>contact</span>
        </Link>{" "}
        page to request the addition of your favorite rider.
      </Body>
    </Section>
  );
}

const Body = styled(H4)`
  color: ${colors.gray4};
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  text-align: center;

  span {
    color: ${colors.white};

    :hover {
      text-decoration: underline;
    }
  }
`;
