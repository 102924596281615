import * as React from "react";

import AthletesList from "../components/AthletesList";
import ExploreBodyText from "../components/ExploreBodyText";
import BannerWithEyebrow from "../components/banners/BannerWithEyebrow";
import Layout from "../components/meta/Layout";
import Seo from "../components/meta/Seo";

export default function Athletes() {
  return (
    <Layout>
      <Seo
        title="Athletes"
        description="Find a pro skier or snowboarder. See what gear they ride. Check out their setups."
      />
      {/* TODO: Featured Riders Section */}
      <BannerWithEyebrow
        eyebrow="Find your favorite riders"
        headline="Athletes"
      />
      <AthletesList />
      <ExploreBodyText />
    </Layout>
  );
}
